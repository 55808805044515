const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

function standalone() {
  // iPad always return standalone mode even in browser
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true
  } else if(isMobile) {
    return false
  } else {
    return true
  }
}

export default standalone
import { useContext, useEffect, useState } from 'react'
import { SearchBar, List } from 'antd-mobile'
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'

import { AppContext } from '../contexts/appContext'
import { Hashtag, hashtagConverter } from '../converters/Hashtag'

function TagPicker({ appendTag }: any) {
  const { db } = useContext(AppContext)
  const tagsRef = collection(db, 'hashtags').withConverter(hashtagConverter)

  const [tagList, setTagList] = useState<Hashtag[]>([])
  const [filteredList, setFilteredList] = useState<Hashtag[]>([])

  const getTagList = async () => {
    const q = query(tagsRef, orderBy('count', 'desc'), limit(100))
    const tagsDocs = await getDocs(q)

    const tags: Hashtag[] = []
    tagsDocs.forEach(doc => tags.push(doc.data()))

    setTagList(tags)
    setFilteredList(tags)
  }

  useEffect(() => {
    getTagList()
  }, [])

  const changeHandler = (val: string) => (
    setFilteredList(tagList.filter(tag => tag.name.startsWith(val)))
  )

  const clickHanlder = (name: string) => {
    appendTag(name)
    // setText((caption: string) => caption.concat(' #'+name))
  }
  
  return (
    <div>
      <SearchBar onChange={changeHandler} />
      <div className='h-72 overflow-y-scroll'>
        <List style={{'--border-top': '0px', '--border-bottom': '0px', '--border-inner': '0px'}}>
          {filteredList.map(tag => {
            const name = tag.name
            const count = tag.count
            return (
              <List.Item key={name} 
                  onClick={() => clickHanlder(name)} 
                  arrow={<div className='text-sm'>{count} views</div>} >
                #{name}
              </List.Item>
            )
          })}
        </List>
      </div>
    </div>
  )
}

export default TagPicker
import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'

class Hashtag {
  name: string
  count: number

  constructor(name: string, count: number) {
    this.name = name
    this.count = count
  }
  toString() {
    return this.name + ', ' + this.count
  }
}

const hashtagConverter = {
  toFirestore: (hashtag: Hashtag) => {
    return {
      name: hashtag.name,
      count: hashtag.count,
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options)
    return new Hashtag(data.name, data.count)
  }
}

export { Hashtag, hashtagConverter }
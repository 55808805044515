import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Dialog, Image, NavBar, Result, SpinLoading, SwipeAction, Tabs, Toast } from 'antd-mobile'
import { AppstoreOutline, DeleteOutline, HeartOutline, LeftOutline } from 'antd-mobile-icons'
import { collection, deleteDoc, doc, getDocs, orderBy, query, QueryDocumentSnapshot, where } from 'firebase/firestore'
import { User } from 'firebase/auth'

import { AppContext } from '../contexts/appContext'
import BottomNav from '../components/BottomNav'
import urlSwapper from '../utils/urlSwapper'
import errorHandler from '../utils/errorHandler'
import { Post, postConverter } from '../converters/Post'
import { likeConverter } from '../converters/Like'

const logoutIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" /></svg>

function MyProfile () {
  const navigate = useNavigate()
  const { auth, db } = useContext(AppContext)
  const postsRef = collection(db, 'posts').withConverter(postConverter)
  const likesRef = collection(db, 'likes').withConverter(likeConverter)

  const [currentUser, setCurrentUser] = useState<User | null>(auth.currentUser)
  const [postList, setPostList] = useState<QueryDocumentSnapshot<Post>[] | null>([])
  const [likedPostList, setLikedPostList] = useState<QueryDocumentSnapshot<Post>[] | null>()

  const getCurrentUser = () => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user)
      } else {
        navigate('/auth')
      }
    })
  }

  const getPostList = async () => { 
    try {
      if (currentUser) {
        const q = query(postsRef, orderBy('createdAt', 'desc'), where('createdBy', '==', currentUser.uid))
        const postDocs = await getDocs(q)
        setPostList(postDocs.docs)
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const getLikedPostList = async () => {  
    try {
      if (currentUser) {
        const q1 = query(likesRef, where('user', '==', currentUser.uid))
        const likeDocs = await getDocs(q1)

        if (!likeDocs.empty) {
          const postIds = likeDocs.docs.map(doc => doc.data().post)
  
          const q2 = query(postsRef, where('__name__', 'in', postIds))
          const postDocs = await getDocs(q2)
          setLikedPostList(postDocs.docs)
        } else {
          setLikedPostList([])
        }
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const logout = async () => {
    try {
      await auth.signOut()
      setCurrentUser(null)
    } catch (error: any) {
      errorHandler(error)
    }
  }

  const deleteHandler = async (postId: string) => {
    const result = await Dialog.confirm({ 
      content: 'Are you sure you want to delete this post?',
      cancelText: 'Cancel',
      confirmText: 'Confirm'
    })
    if (result) {
      await deletePost(postId)
    }
  }

  const deletePost = async (postId: string) => {
    try {
      await deleteDoc(doc(db, 'posts', postId))
      await getPostList()
      Toast.show({ content: 'Post was deleted', position: 'bottom'})
    } catch (error) {
      errorHandler(error)
    }
  }

  const Fallback = ({ post }: {post: QueryDocumentSnapshot<Post>}) => {
    const status = post.data().status
    if (status === 'job.failed') {
      return <Result
        status='error'
        title='Error'
        description={post.data().error}
      />
    } else if (status === 'job.completed') {
      return null
    } else {
      return <Result
        status='waiting'
        title='Processing...'
        description=''
      />
    }
  }

  const playList = (list: QueryDocumentSnapshot<Post>[], postId: string) => {
    navigate('/listplayer', { 
      state: { list: list.map(doc => ({id: doc.id, data: doc.data()})), postId: postId }
    })
  }

  useEffect(() => {
    getCurrentUser()
    getPostList()
    getLikedPostList()
  }, [currentUser])

  return (
    <div className='max-w-lg h-full m-auto flex flex-col'>    
      <div className='h-[45px]'>
        <NavBar backArrow={false} style={{ '--border-bottom': '1px #eee solid'}}>My Profile</NavBar>
      </div>
      {currentUser && 
        <div className='flex flex-col items-center'>
          <div className='my-4'>
            <Avatar src={currentUser.photoURL || ''} style={{ '--border-radius': '96px', '--size': '96px' }} alt={currentUser.displayName || ''} />
          </div>
          <div className='font-semibold'>@{currentUser.displayName}</div>
          <div className='my-8 flex cursor-pointer'>
            <div className='py-2 px-10 border border-[#e3e3e4]' onClick={() => navigate('/edit_profile')}>Edit Profile</div>
            <div className='border border-[#e3e3e4] p-1 ml-1' onClick={logout}>{logoutIcon}</div>
          </div>
        </div>
      }
      <hr />
      <div className='grow overflow-y-auto'>
        <Tabs>
          <Tabs.Tab title={<AppstoreOutline />} key='myupload'>
            <div className='grid grid-cols-3 gap-1'>
              {postList ?
                postList.map(post => 
                  <div key={post.id} className='relative'>
                    <SwipeAction rightActions={[
                        {key: 'delete', text: <DeleteOutline />, color: 'danger', onClick: () => deleteHandler(post.id) }
                      ]}>
                      <Image src={urlSwapper(post.data().cover)} alt={post.data().title} height={'25vh'}
                        fallback={<Fallback post={post} />} onContainerClick={() => playList(postList, post.id)} 
                      />
                    </SwipeAction>
                    <div className='absolute right-1 top-1/2'><LeftOutline color='white' /></div>
                  </div>
                ) :
                <SpinLoading />
              }
            </div>
          </Tabs.Tab>
          <Tabs.Tab title={<HeartOutline />} key='mylikes'>
            <div className='grid grid-cols-3 gap-1 cursor-pointer'>
              {likedPostList ?
                  likedPostList.map(post => 
                    <Image key={post.id} src={urlSwapper(post.data().cover)} 
                      alt={post.data().title} height={'25vh'}
                      onClick={() => playList(likedPostList, post.id)}
                    />
                  ) :
                  <SpinLoading />
              }
            </div>
          </Tabs.Tab>
        </Tabs>
      </div>
      <BottomNav darktheme={false} />
    </div>
  )
}

export default MyProfile
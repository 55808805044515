import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Avatar, Image, NavBar, Toast } from 'antd-mobile'
import { collection, getDocs, orderBy, query, QueryDocumentSnapshot, where } from 'firebase/firestore'

import BottomNav from '../components/BottomNav'
import urlSwapper from '../utils/urlSwapper'
import { AppContext } from '../contexts/appContext'
import errorHandler from '../utils/errorHandler'
import { Post, postConverter } from '../converters/Post'

function UserProfile () {
  const navigate = useNavigate()
  const { state }: any = useLocation()
  const user = state

  const { db } = useContext(AppContext)
  const postsRef = collection(db, 'posts').withConverter(postConverter)

  const [postList, setPostList] = useState<QueryDocumentSnapshot<Post>[]>()

  const getPostList = async () => {
    try {
      if (user) {
        const q = query(postsRef, orderBy('createdAt', 'desc'), where('createdBy', '==', user.uid))
        const postsDocs = await getDocs(q)
        setPostList(postsDocs.docs)
      } else {
        Toast.show({ content: 'User not found, back to home'})
        navigate('/')
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const playList = (list: QueryDocumentSnapshot<Post>[], postId: string) => {
    navigate('/listplayer', { 
      state: { list: list.map(doc => ({id: doc.id, data: doc.data()})), postId: postId }
    })
  }

  useEffect(() => {
    getPostList()
  }, [])

  return (
    <div className='max-w-lg h-full m-auto flex flex-col'>    
      <div className='h-[45px]'>
        <NavBar backArrow={false} style={{ '--border-bottom': '1px #eee solid'}}>User Profile</NavBar>
      </div>
      {user && 
        <div className='flex flex-col items-center'>
          <div className='my-4'>
            <Avatar src={user.photoURL || ''} style={{ '--border-radius': '96px', '--size': '96px' }} alt={user.displayName}/>
          </div>
          <div className='font-semibold'>@{user.displayName}</div>
        </div>
      }
      <hr className='my-8' />
      <div className='grow overflow-y-auto'>
        <div className='grid grid-cols-3 gap-1 cursor-pointer'>
          {postList &&
            postList.map(post => 
              <Image key={post.id} src={urlSwapper(post.data().cover)} 
                alt={post.data().title} height={'25vh'}
                onClick={() => playList(postList, post.id)} 
              />
            )
          }
        </div>
      </div>

      <BottomNav darktheme={false} />
    </div>
  )
}

export default UserProfile
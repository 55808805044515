import { QueryDocumentSnapshot, serverTimestamp, SnapshotOptions } from 'firebase/firestore'

class Post {
  commentCount: number
  cover: string
  createdAt: Date
  createdBy: string
  error: string
  hashtags: string[]
  jobId: string
  likeCount: number
  status: string
  title: string
  video: string

  constructor(commentCount: number, cover: string, createdAt: Date, createdBy: string, error: string, hashtags: string[], jobId: string, likeCount: number, status: string, title: string, video: string) {
    this.commentCount = commentCount
    this.cover = cover
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.error = error
    this.hashtags = hashtags
    this.jobId = jobId
    this.likeCount = likeCount
    this.status = status
    this.title = title
    this.video = video
  }
  toString() {
    return this.title + ', ' + this.status
  }
}

const postConverter = {
  toFirestore: (post: Post) => {
    return {
      commentCount: post.commentCount,
      cover: post.cover,
      createdAt: serverTimestamp(),
      createdBy: post.createdBy,
      error: post.error,
      hashtags: post.hashtags,
      jobId: post.jobId,
      likeCount: post.likeCount,
      status: post.status,
      title: post.title,
      video: post.video
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options)
    return new Post(data.commentCount, data.cover, data.createdAt.toDate(), data.createdBy, data.error, data.hashtags, data.jobId, data.likeCount, data.status, data.title, data.video)
  }
}

export { Post, postConverter }
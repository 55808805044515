import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'

class Notification {
  title: string
  subtitle: string
  content: string

  constructor(title: string, subtitle: string, content: string) {
    this.title = title
    this.subtitle = subtitle
    this.content = content
  }
  toString() {
    return this.title + ', ' + this.subtitle
  }
}

const notificationConverter = {
  toFirestore: (notification: Notification) => {
    return {
      title: notification.title,
      subtitle: notification.subtitle,
      content: notification.content
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options)
    return new Notification(data.title, data.subtitle, data.content)
  }
}

export { Notification, notificationConverter }
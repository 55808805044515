import { createContext } from 'react'
import { initializeApp } from "firebase/app"
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth"
import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

import firebaseConfig from '../firebaseConfig.json'

export const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
  })

export const auth = getAuth(app)
setPersistence(auth, browserLocalPersistence)

export const storage = getStorage(app)
export const AppContext = createContext({ app, auth, db, storage })
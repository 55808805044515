// import Marquee from 'react-fast-marquee'

import { Post } from '../converters/Post'
import { User } from '../converters/User'

function Footer({ post, user }: {post: Post, user: User}) {
  const hashtags = post.hashtags
  const joinedTags = hashtags.length === 0 ? '' : '#'+hashtags.join(' #')
  const caption = post.title+joinedTags

  return (
    <div className='relative text-[#e9e9e9] bottom-20 ml-3'>
      { user && <div>@{user.displayName}</div>}
      <div className='text-xs w-10/12 my-2'>{caption}</div>
      {/* <div className='flex items-center text-sm font-light'>
        <SoundOutline />
        <Marquee gradient={false} pauseOnHover={true} speed={40} style={{ maxWidth: '40%', marginLeft: '10px'}}>
          <div>Avicli - Waiting For Love (ft....)</div>
        </Marquee>
      </div> */}
    </div>
  )
}

export default Footer
import { useContext, useEffect, useState } from 'react'
import { Dialog, List, NavBar } from 'antd-mobile'
import { collection, getDocs, QueryDocumentSnapshot } from 'firebase/firestore'

import BottomNav from '../components/BottomNav'
import { AppContext } from '../contexts/appContext'
import { Notification, notificationConverter } from '../converters/Notification'
import errorHandler from '../utils/errorHandler'

function Inbox() {
  const { db } = useContext(AppContext)
  const notificationsRef = collection(db, 'notifications').withConverter(notificationConverter)
  
  const [notificationList, setNotificationList] = useState<QueryDocumentSnapshot<Notification>[]>()

  const getNotificationList = async () => {
    try {
      const notifications = await getDocs(notificationsRef)   
      setNotificationList(notifications.docs)
    } catch (error) {
      errorHandler(error)
    }
  }

  const clickHandler = (content: string) => {
    Dialog.show({ 
      content: content,
      closeOnAction: true,
      actions: [{ key: 'ok', text: 'OK'}]
    })
  }

  useEffect(() => {
    getNotificationList()
  }, [])

  return (
    <div className='max-w-lg h-full m-auto flex flex-col'>    
      <div className='h-[45px]'>
        <NavBar backArrow={false} style={{ '--border-bottom': '1px #eee solid'}}>Inbox</NavBar>
      </div>
      <div className='grow overflow-y-scroll'>
        {notificationList &&
          <List>
            {notificationList.map(notification => (
              <List.Item key={notification.id} description={notification.data().subtitle} clickable 
                  onClick={() => clickHandler(notification.data().content) }>
                {notification.data().title}
              </List.Item>
            ))}
          </List>
        }
      </div>
      <BottomNav darktheme={false} />
    </div>
  )
}

export default Inbox
import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'

class Like {
  post: string
  user: string

  constructor(post: string, user: string) {
    this.post = post
    this.user = user
  }
  toString() {
    return this.post + ', ' + this.user
  }
}

const likeConverter = {
  toFirestore: (like: Like) => {
    return {
      post: like.post,
      user: like.user,
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options)
    return new Like(data.post, data.user)
  }
}

export { Like, likeConverter }
import { useContext, useEffect, useRef, useState } from 'react'
import { PlayOutline } from 'antd-mobile-icons'
import { doc, getDoc } from 'firebase/firestore'

import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import { Post } from '../converters/Post'
import urlSwapper from '../utils/urlSwapper'
import useIntersectionObserver from '../utils/useIntersectionObserver'
import { User, userConverter } from '../converters/User'
import { AppContext } from '../contexts/appContext'

function Player ({ postId, post }: {postId: string, post: Post}) {
  const { db } = useContext(AppContext)

  const [userId, setUserId] = useState('')
  const [user, setUser] = useState<User>()
  const [hidden, setHidden] = useState(false)

  const src = urlSwapper(post.video)
  
  const playerRef: any = useRef(null)
  const entry = useIntersectionObserver(playerRef, { threshold: 0.5 })
  const isVisible = !!entry?.isIntersecting

  if (playerRef.current && !isVisible) {
    playerRef.current.pause()
  }

  const playHandler = () => {
    if (playerRef.current.paused) {
      setHidden(true)
      playerRef.current.play()
    } else {
      setHidden(false)
      playerRef.current.pause()
    }
  }

  const getUser = async () => {
    const userRef = doc(db, 'users', post.createdBy).withConverter(userConverter)
    const userDoc = await getDoc(userRef)
    setUserId(userDoc.id)
    setUser(userDoc.data())
  }

  useEffect(() => {
    getUser()
  }, [])
  
  return (
    <div className='relative w-full h-full snap-start'  >
      <PlayOutline fontSize={64} color='#fff' onClick={playHandler} 
        className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${hidden && 'hidden'} cursor-pointer`}
      />
      {/* autoPlay is necessary */}
      <video src={src} ref={playerRef} playsInline autoPlay preload='auto' loop
        className='object-cover w-full h-full' onClick={playHandler} 
      />
      {user && isVisible && <>      
        <Sidebar postId={postId} post={post} userId={userId} user={user} />
        <Footer post={post} user={user} />
      </>}
    </div>
  )
}

export default Player
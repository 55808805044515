import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'

class User {
  displayName: string
  photoURL: string

  constructor(displayName: string, photoURL: string) {
    this.displayName = displayName
    this.photoURL = photoURL
  }
  toString() {
    return this.displayName + ', ' + this.photoURL
  }
}

const userConverter = {
  toFirestore: (user: User) => {
    return {
      displayName: user.displayName,
      photoURL: user.photoURL,
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options)
    return new User(data.displayName, data.photoURL)
  }
}

export { User, userConverter }
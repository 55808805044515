import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Button, Dialog, Empty, List, Popup, TextArea } from 'antd-mobile'
import { HeartFill, MessageFill } from 'antd-mobile-icons'
import { addDoc, collection, deleteDoc, doc, getDocs, increment, orderBy, query, QueryDocumentSnapshot, updateDoc, where } from 'firebase/firestore'

import { Post } from '../converters/Post'
import { User } from '../converters/User'
import { AppContext } from '../contexts/appContext'
import { Like, likeConverter } from '../converters/Like'
import { Comment, commentConverter } from '../converters/Comment'
import errorHandler from '../utils/errorHandler'

const shareIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor"><path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" /></svg>
const homeUrl = 'https://tiktok-clone.pages.dev/'
const shareTitle = 'App'
const shareText = 'To be determined'
const commentMaxLength = 120

function Sidebar({postId, post, userId, user }: {postId: string, post: Post, userId: string, user: User}) {
  const navigate = useNavigate()

  const { auth, db } = useContext(AppContext)
  const currentUser = auth.currentUser
  const likesRef = collection(db, 'likes').withConverter(likeConverter)
  const commentsRef = collection(db, 'comments').withConverter(commentConverter)
  const postRef = doc(db, 'posts', postId)

  const [comment, setComment] = useState('')
  const [commentList, setCommentList] = useState<QueryDocumentSnapshot<Comment>[]>()
  const [likesCount, setLikesCount] = useState<number>(post.likeCount)
  const [commentsCount, setCommentsCount] = useState<number>(post.commentCount)
  const [commentsPopup, setCommentsPopup] = useState(false)

  const [liked, setLiked] = useState<string | null>(null)

  const markLiked = async () => {
    try {
      if (currentUser) {
        const q = query(likesRef, where('post', '==', postId), where('user', '==', currentUser.uid))
        const likes = await getDocs(q)
        if (!likes.empty) setLiked(likes.docs[0].id)
        else setLiked(null)
      }
    } catch (error) {
      errorHandler(error)
    }
  } 

  const onClickLike = async () => {
    try {
      if (currentUser) {
        if (!liked) {
          const likeDoc = await addDoc(likesRef, new Like(postId, currentUser.uid))
          await updateDoc(postRef, { likeCount: increment(1) })

          setLiked(likeDoc.id)
          setLikesCount(likes => likes+1)
        } else {
          await deleteDoc(doc(likesRef, liked))
          await updateDoc(postRef, { likeCount: increment(-1) })

          setLiked(null)
          setLikesCount(likes => likes-1)
        }
      } else {
        Dialog.alert({content: 'Please login first', confirmText: 'OK'})
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const onClickComment = async () => {
    try {
      const q = query(commentsRef, orderBy('createdAt', 'desc'), where('post', '==', postId))
      const comments = await getDocs(q)
      setCommentList(comments.docs)
      setCommentsPopup(true)
    } catch (error) {
      errorHandler(error)
    }
  }

  const submitComment = async () => {
    try {
      await addDoc(commentsRef, new Comment(new Date(), comment, postId, {
        displayName: currentUser?.displayName || '', 
        avatar: currentUser?.photoURL || ''}
      ))

      await updateDoc(postRef, { commentCount: increment(1) })
      setCommentsCount(count => count+1)
      setCommentsPopup(false)
      setComment('')
    } catch (error) {
      errorHandler(error)
    }
  }

  const share = async () => {
    try {
      await navigator.share({
        title: shareTitle,
        text: shareText,
        url: `${homeUrl}?id=${postId}`,
      })
    } catch(error: any) {
      if (!error.toString().includes('AbortError')) {
        Dialog.alert({content: "Auto share not supported. Please manually share the clip.", confirmText: 'OK'})
      }
    }
  }

  function timeSince(date: Date) {
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000)
    let interval = seconds / 31536000
  
    if (interval > 1) {
      return Math.floor(interval) + " years"
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + " months"
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + " days"
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + " hours"
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + " minutes"
    }
    return Math.floor(seconds) + " seconds"
  }

  useEffect(() => {
    markLiked()
  }, [post, postId, currentUser])

  return (
    <div className='absolute top-1/2 right-2 flex flex-col items-center'>
      <div className='mb-9 cursor-pointer' onClick={() => navigate('/userprofile', { 
          state: { uid: userId, displayName: user.displayName, photoURL: user.photoURL } 
        })}>
        <Avatar src={user.photoURL} style={{ '--border-radius': '32px' }} alt={user.displayName} />
      </div>
      <div className='flex flex-col items-center text-white text-xs cursor-pointer'>
        <div 
          className='flex flex-col items-center'
          onClick={onClickLike}
        >
          <HeartFill fontSize={35} color={liked ? '#ea4359': '#fff'} />
          <div>{likesCount}</div>
        </div>
        <div 
          className='flex flex-col items-center mt-2 mb-4'
          onClick={onClickComment}
        >
          <MessageFill fontSize={35} color={'#fff'} />
          <div>{commentsCount}</div>
        </div>
        <div onClick={share} className='mb-4'>{shareIcon}</div>
      </div>
      <div>
        <Popup visible={commentsPopup} 
                showCloseButton
                onClose={() => setCommentsPopup(false)} 
                bodyStyle={{ 
                  overflowY: 'scroll',
                  height: '75vh',
                  maxWidth: '512px',
                  left: '50%',
                  translate: '-50%, 0',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px'
                }}>
          <List header={!currentUser && 'Please login to post comment'}>
            {currentUser && <List.Item>
              <div className='border p-1 mt-6'>
                <TextArea 
                  placeholder='Add comment...'
                  value={comment}
                  maxLength={commentMaxLength}
                  rows={3}
                  showCount
                  onChange={val => val.length <= commentMaxLength && setComment(val)}
                />
              </div>
              <Button block color='primary' fill='none' size='small' onClick={submitComment}>SUBMIT</Button>
            </List.Item>}
            { (commentList && commentList.length !== 0) ? commentList.map(comment => (
              <List.Item key={comment.id} prefix={<Avatar src={comment.data().user.avatar} />}
                description={comment.data().content}>
                <span>{comment.data().user.displayName}</span>
                <span className='ml-2 text-xs'>{timeSince(comment.data().createdAt)} ago</span>
              </List.Item>
            )) : 
              <Empty description='No comment yet' />
            }
          </List>
        </Popup>
      </div>
    </div>
  )
}

export default Sidebar
import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DotLoading, InfiniteScroll } from 'antd-mobile'
import { collection, getDocs, limit, query, QueryDocumentSnapshot, where } from 'firebase/firestore'

import { AppContext } from '../contexts/appContext'
import Player from '../components/Player'
import BottomNav from '../components/BottomNav'

import errorHandler from '../utils/errorHandler'
import { Post, postConverter } from '../converters/Post'
import PWAPrompt from '../components/PWAPrompt'
import standalone from '../utils/standalone'

const randomUrl = 'https://frosty-poetry-ce29.nomentech.workers.dev/'

function Home() {
  let [searchParams] = useSearchParams()
  const postId = searchParams.get('id')

  const { db } = useContext(AppContext)
  const postsRef = collection(db, 'posts').withConverter(postConverter)

  // BottomNav height(49px), safeArea height=30px or 10 depends on mode
  const innerHeigh = window.innerHeight
  const safeHeight = standalone() ? 30 : 10
  const height = innerHeigh-49-safeHeight

  const [postList, setPostList] = useState<QueryDocumentSnapshot<Post>[]>([])

  const getPostList = async () => {
    try {
      const response = await fetch(randomUrl)
      const randomPosts = await response.json()

      const q = query(postsRef, where('status', '==', 'job.completed'), where('__name__', 'in', randomPosts))
      const posts = await getDocs(q)      
      setPostList(list => [...list, ...posts.docs])
    } catch (error) {
      errorHandler(error)
    }
  }

  const getPost = async () => {
    const q = query(postsRef, where('__name__', '==', postId))
    const posts = await getDocs(q)      
    setPostList(posts.docs)
  }

  const loadMore = async () => {
    await getPostList()
  }

  useEffect(() => {
    if (postId) {
      getPost()
    }
    // loadMore will be called automacticlly, no need to call getPostList()
    // getPostList()
  }, [])

  return (
    <div className='max-w-lg m-auto'>
      <div className={`relative w-full overflow-y-scroll snap-y snap-mandatory bg-black`} style={{height: height}}>
        {postList.map((post, index) => (
            <Player key={index} postId={post.id} post={post.data()} />
          ))
        }
        <InfiniteScroll loadMore={loadMore} hasMore={true}>
          <span>Loading</span>
          <DotLoading />
        </InfiniteScroll>
      </div>
      <BottomNav />
      <PWAPrompt />
    </div>
  )
}

export default Home
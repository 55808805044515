import { Button } from 'antd-mobile'

function BlockButton({ label, size='middle', color ='primary', clickHandler }: any) {
  return (
    <div className='my-2 font-bold'>
      <Button block size={size} color={color} onClick={clickHandler}>
        {label}
      </Button>
    </div>
  )
}

export default BlockButton
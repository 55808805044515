import { Input } from 'antd-mobile'

function TextInput({ placeholder, type='text', value, changeHandler}: any) {
  return (
    <div className='my-1 border border-[#1618230f] rounded-sm p-3 bg-[#1618230f]'>
      <Input
        type={type} placeholder={placeholder} value={value}
        onChange={(value) => changeHandler(value)}
      />
    </div>
  )
}

export default TextInput
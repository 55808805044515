import { useLocation, useNavigate } from 'react-router-dom'

import Player from '../components/Player'
import BottomNav from '../components/BottomNav'
import standalone from '../utils/standalone'
import { useEffect } from 'react'
import { Dialog } from 'antd-mobile'

function Home() {
  const navigate = useNavigate()
  const { state }: any = useLocation()
  const postList = state.list
  const postId = state.postId

  // BottomNav height(49px), safeArea height=30px or 10 depends on mode
  const innerHeigh = window.innerHeight
  const safeHeight = standalone() ? 30 : 10
  const height = innerHeigh-49-safeHeight

  if (postId) {
    // move specified post to top
    postList.sort((x: any, y: any) => (
      x.id === postId ? -1 : y.id === postId ? 1 : 0
    ))
  }

  useEffect(() => {
    if (postList.length === 0) {
      Dialog.alert({
        content: 'No clip found. Back to home.',
        confirmText: 'OK',
        onConfirm: () => navigate('/')
      })
    }
  }, [postList])

  return (
    <div className='max-w-lg m-auto'>
      <div className='relative w-full overflow-y-scroll snap-y snap-mandatory bg-black' style={{height: height}}>
        {postList && postList.map((post: any) => (
            <Player key={post.id} postId={post.id} post={post.data} />
          ))
        }
      </div>
      <BottomNav />
    </div>
  )
}

export default Home
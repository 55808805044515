import { QueryDocumentSnapshot, serverTimestamp, SnapshotOptions } from 'firebase/firestore'

type userType = {
  displayName: string,
  avatar: string,
}

class Comment {
  createdAt: Date
  content: string
  post: string
  user: userType

  constructor(createdAt: Date, content: string, post: string, user: userType) {
    this.createdAt = createdAt
    this.content = content
    this.post = post
    this.user = user
  }
  toString() {
    return this.content + ', ' + this.post + ', ' + this.user
  }
}

const commentConverter = {
  toFirestore: (comment: Comment) => {
    return {
      createdAt: serverTimestamp(),
      content: comment.content,
      post: comment.post,
      user: comment.user,
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options)
    return new Comment(data.createdAt.toDate(), data.content, data.post, data.user)
  }
}

export { Comment, commentConverter }
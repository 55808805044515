import { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import { Dialog } from 'antd-mobile'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { app, auth, db, storage, AppContext } from './contexts/appContext'

import Home from './pages/Home'
import Auth from './pages/Auth'
import MyProfile from './pages/MyProfile'
import Discover from './pages/Discover'
import Postpage from './pages/Postpage'
import Inbox from './pages/Inbox'
import EditProfile from './pages/EditProfile'
import UserProfile from './pages/UserProfile'
import ListPlayer from './pages/ListPlayer'

function App() {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false)  
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>()

  const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
    setNewVersionAvailable(true)
    setWaitingWorker(registration && registration.waiting)
  }

  const updateServiceWorker = () => {
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" })
    setNewVersionAvailable(false)
    window.location.reload()
  }

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate })

    if (newVersionAvailable) {
      Dialog.confirm({ 
        content: 'A new version is available. Reload to update',
        cancelText: 'Not now',
        confirmText: 'Reload now',
        onConfirm: updateServiceWorker
      })
    }
  }, [])

  return (
    <AppContext.Provider value={{ app, auth, db, storage }}>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/auth' element={<Auth />} />
          <Route path='/discover' element={<Discover />} />
          <Route path='/post' element={<Postpage />} />
          <Route path='/inbox' element={<Inbox />} />
          <Route path='/myprofile' element={<MyProfile />} />
          <Route path='/userprofile' element={<UserProfile />} />
          <Route path='/edit_profile' element={<EditProfile />} />
          <Route path='/listplayer' element={<ListPlayer />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  )
}

export default App

import { ChangeEvent, useContext, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TabBar } from 'antd-mobile'
import { VideoOutline, SearchOutline, AddOutline, MessageOutline, UserOutline } from 'antd-mobile-icons'

import { AppContext } from '../contexts/appContext'
import standalone from '../utils/standalone'

function BottomNav({ darktheme=true }: any) {
  const { auth } = useContext(AppContext)
  const currentUser = auth.currentUser

  const location = useLocation()
  const { pathname } = location

  const safeHeight = standalone() ? 30 : 10

  const navigate = useNavigate()

  const inputFile = useRef<HTMLInputElement | null>(null)
  const openFilePicker = () => {
    if (!currentUser) {
      navigate('/auth')
    } else {
      inputFile && inputFile?.current?.click()
    }
  }

  const filePickerChangeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    navigate('/post', { state: files })
  }

  const tabs = [
    {
      key: '/',
      title: (active: boolean) => active ? <div className='text-white'>Clip</div> : 'Clip',
      icon: (active: boolean) => active ? <VideoOutline color='#fff'/> : <VideoOutline />
    },
    {
      key: '/discover',
      title: (active: boolean) => active ? <div className='text-black'>Discover</div> : 'Discover',
      icon: (active: boolean) => active ? <SearchOutline color='#000'/> : <SearchOutline />
  
    },
    {
      key: '/post',
      icon: <div onClick={openFilePicker}>
              <div className={`w-9 h-7 rounded-lg flex justify-center items-center ${darktheme ? 'bg-white' : 'bg-black'}`}
                  // style={{ 'filter': 'drop-shadow(4px 0px 0px #fd3e3e) drop-shadow(-4px 0px 0px #4de8f4)' }}
                >
                <AddOutline color={darktheme ? '#000' : '#fff'} /> 
              </div>
              <input className='hidden' type='file' ref={inputFile} accept='video/*'
                onChange={filePickerChangeHandler} />
            </div>
    },
    {
      key: '/inbox',
      title: (active: boolean) => active ? <div className='text-black'>Inbox</div> : 'Inbox',
      icon: (active: boolean) => active ? <MessageOutline color='#000'/> : <MessageOutline /> 
    },
    {
      key: '/myprofile',
      title: (active: boolean) => active ? <div className='text-black'>Me</div> : 'Me',
      icon: (active: boolean) => active ? <UserOutline color='#000'/> : <UserOutline />
    },
  ]
  
  return (
    <div className={`${darktheme ? 'bg-black' : 'bg-white'}`}>
      <TabBar activeKey={pathname} onChange={(value) => value !== '/post' && navigate(value)}>
        {tabs.map(item => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
        ))}
      </TabBar>
      {/* add extra height below to create safe area */}
      <div className='w-full' style={{height: safeHeight}}></div>
    </div>
  )
}

export default BottomNav
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog, Input, Toast } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { addDoc, collection } from 'firebase/firestore'

import { AppContext } from '../contexts/appContext'
import TextInput from '../components/TextInput'
import BlockButton from '../components/BlockButton'
import TextButton from '../components/TextButton'
import errorHandler from '../utils/errorHandler'
import { User, userConverter } from '../converters/User'

function Auth() {
  const navigate = useNavigate()

  const { auth, db } = useContext(AppContext)
  const usersRef = collection(db, 'users').withConverter(userConverter)


  const [mode, setMode] = useState(true) // true == login, false == signup
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [resetEmail, setResetEmail] = useState('')
  const [showResetDialog, setShowResetDialog] = useState(false)

  const submitHandler = () => {
    if (mode) {
      login()
    } else {
      signup()
    }
  }

  const login = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
      navigate('/myprofile')
    } catch (error) {
      errorHandler(error)
    }
  }

  const signup = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      const firebaseUser = userCredential.user
      const displayName = email.split('@')[0]

      updateProfile(firebaseUser, { displayName: displayName })
      await addDoc(usersRef, new User(displayName, ''))

      navigate('/myprofile')
    } catch (error) {
      errorHandler(error)
    }
  }

  const passwordResetHandler = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail)
      Toast.show({ content: 'Check your email to reset password', position: 'bottom'})
    } catch (error) {
      errorHandler(error)
    }
  }

  return (
    <div className='flex flex-col items-center justify-between h-full max-w-lg m-auto'>
      <div className='flex flex-col items-center mt-16'>
        <div className='m-4 text-3xl font-bold'>{mode ? 'Log in' : 'Sign up'}</div>
        <div className='flex flex-col w-[375px]'>
          <TextInput type='email' placeholder='Email' changeHandler={setEmail} />
          <TextInput type='password' placeholder='Password' changeHandler={setPassword} />

          { mode && <div className='my-[9px] text-[#161823bf] text-xs font-semibold cursor-pointer' 
                        onClick={() => setShowResetDialog(true)}>
                          Forgot password?
                    </div> }
          <BlockButton label={mode ? 'Log in' : 'Sign up'} size='large' clickHandler={submitHandler} />
        </div>
        <div className='flex items-center mt-10'>
          <LeftOutline fontSize={14} />        
          <TextButton label='Back to home' size='mini' clickHandler={() => navigate('/')} />
        </div>
      </div>
      <div className='text-[#161823bf] w-full h-16 border-t border-t-[#1618230f] flex items-center justify-center'>
        { mode ? 'Don\'t have an account?' : 'Already have an account' } 
        <TextButton label={mode ? 'Signup' : 'Log in'} size='mini' color='primary' clickHandler={() => setMode(mode => !mode)} />
      </div>
      <div>
        <Dialog 
          visible={showResetDialog} 
          content={<Input type='email' placeholder='Email' onChange={val => setResetEmail(val)}/>}
          closeOnAction
          onClose={() => setShowResetDialog(false)}
          actions={[[
            { key: 'cancel', text: 'Cancel' },
            { key: 'confirm', text: 'Send', onClick: passwordResetHandler }
          ]]}
        />
      </div>
    </div>
  )
}

export default Auth
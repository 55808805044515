import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DotLoading, Form, Result, TextArea } from 'antd-mobile'

import { User } from 'firebase/auth'
import { collection, getDocs, query, where, addDoc, updateDoc, increment } from 'firebase/firestore'

import { AppContext } from '../contexts/appContext'
import BlockButton from '../components/BlockButton'
import TextButton from '../components/TextButton'
import TagPicker from '../components/TagPicker'
import errorHandler from '../utils/errorHandler'
import { Hashtag, hashtagConverter } from '../converters/Hashtag'
import { Post, postConverter } from '../converters/Post'

const uploadUrl = process.env.REACT_APP_WORKER
const maxLength = 120

function Postpage() {
  const { db } = useContext(AppContext)
  const navigate = useNavigate()

  const { auth } = useContext(AppContext)  
  const { state }: any = useLocation()

  const [submitting, setSubmitting] = useState(false)
  const [done, setDone] = useState(false)
  const [currentUser, setCurrentUser] = useState<User | null>(auth.currentUser)

  const [file, setFile] = useState()
  const [areaText, setAreaText] = useState('')
  const [showPicker, setShowPicker] = useState(false)

  const uploadVideo = async () => {
    const { name } = file!
    const response = await fetch(uploadUrl+name, {
      method: 'POST', 
      body: file
    })
    const data = await response.json()
    return data
  }

  const upsertHashtags = async (tags: any) => {
    tags.map(async (tag: string) => {
      try {
        const tagsRef = collection(db, 'hashtags').withConverter(hashtagConverter)
        const q = query(tagsRef, where('name', '==', tag))
        const tags = await getDocs(q)
  
        if (tags.size === 0) {
          await addDoc(tagsRef, new Hashtag(tag, 1))
        } else {
          const tagRef = tags.docs[0].ref
          await updateDoc(tagRef, {
            name: tag,
            count: increment(1)
          })
        }
      } catch (error) {
        errorHandler(error)
      }
    })
  }

  const createPost = async (title: string, data: any, tags: any) => {
    try {
      const postsRef = collection(db, 'posts').withConverter(postConverter)
      await addDoc(postsRef, new Post(0, '', new Date() ,currentUser!.uid, '', tags, data.id, 0, data.status, title, ''))
    } catch (error) {
      errorHandler(error)
    }
  }

  const submitHandler = async () => {
    try {
      setSubmitting(true)
      const titleAndTags = areaText.split('#')
      const title = titleAndTags[0]
      // remove all white space in a string
      const tags = titleAndTags.splice(1).map(tag => tag.replace(/\s/g, ''))
      const data = await uploadVideo()
      await upsertHashtags(tags)
      await createPost(title, data, tags)
      setDone(true)
    } catch (error) {
      console.log(error)
    } finally {
      setSubmitting(false)
    }
  }

  const appendTag = (tag: string) => {
    setAreaText((prev: string) => {
      if ((prev.length + tag.length) <= (maxLength-2)) {
        return prev.concat(' #'+tag)
      }

      return prev
    })
  }

  const textChangeHandler = (val: string) => {
    if (val.length <= maxLength) {
      setAreaText(val)
    }
  }

  useEffect(() => {
    const user = auth.currentUser
    if (!user) {
      navigate('/auth')
    } else {
      setCurrentUser(user)
    }

    if (!state) {
      navigate('/')
    } else {
      setFile(state[0])
    }
  }, [auth.currentUser, state])

  return (
    <div className='h-full max-w-lg m-auto'>
      { done ? <Result
          status='success' title='Done' 
          description={<a href='/myprofile'>Back to video list</a>} /> :
        <Form footer={
            <BlockButton label={submitting ? <DotLoading color='white' /> : 'POST'} size='large' clickHandler={submitHandler} />
          }>
          <Form.Header>
            <div className='text-2xl font-bold text-center'>New Post</div>
          </Form.Header>
          <Form.Item rules={[{ required: true, message: 'Required' }]}>
            <TextArea 
              placeholder='Title with #hashtag'
              maxLength={maxLength}
              rows={3}
              showCount
              value={areaText}
              onChange={(val) => textChangeHandler(val)}
            />
            <TextButton label='#Hashtags' fill='outline' clickHandler={() => setShowPicker(show => !show)} /> 
          </Form.Item>
          { showPicker && 
            <Form.Item>
              <TagPicker appendTag={appendTag} />
            </Form.Item> }
        </Form>
      }
    </div>
  )
}

export default Postpage
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Dialog, Input, List, NavBar, Toast } from 'antd-mobile'
import { sendEmailVerification, updateEmail, updateProfile, User } from 'firebase/auth'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { collection, doc, updateDoc } from 'firebase/firestore'

import { AppContext } from '../contexts/appContext'
import errorHandler from '../utils/errorHandler'

const storageRoot = 'ttclone'

function EditProfile() {
  const navigate = useNavigate()

  const { auth, db, storage } = useContext(AppContext)
  const usersRef = collection(db, 'users')

  const [currentUser, setCurrentUser] = useState<User | null>(auth.currentUser)
  const [avatarPhoto, setAvatarPhoto] = useState('')
  const [displayName, setDisplayname] = useState('')
  const [email, setEmail] = useState('')

  const [showUsernameDialog, setShowUsernameDialog] = useState(false)
  const [showEmailDialog, setShowEmailDialog] = useState(false)

  const inputFile = useRef<HTMLInputElement | null>(null)
  const openFilePicker = () => {
    if (!currentUser) {
      navigate('/auth')
    } else {
      inputFile && inputFile?.current?.click()
    }
  }
  const filePickerChangeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    try {
      if (files && currentUser) {
        const avatarRef = ref(storage, storageRoot+`/${currentUser.uid}.jpg`)
        await uploadBytes(avatarRef, files[0])
        const url = await getDownloadURL(avatarRef)

        await updateProfile(currentUser, { photoURL: url })
        await updateDoc(doc(usersRef, currentUser.uid), { photoURL: url })

        setAvatarPhoto(url)
        Toast.show({ content: 'Avatar photo updated', position: 'bottom'})
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const getCurrentUser = async () => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user)
        setAvatarPhoto(user.photoURL || '')
      } else {
        navigate('/auth')
      }
    })
  }

  const changeName = async () => {
    try {
      if (displayName && currentUser) {
        await updateProfile(currentUser, {displayName: displayName})
        await updateDoc(doc(usersRef, currentUser.uid), { displayName: displayName })

        Toast.show({ content: 'Username updated', position: 'bottom'})
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const changeEmail = async () => {
    try {
      if (email && currentUser) {
        await updateEmail(currentUser, email)
        Toast.show({ content: 'Email updated', position: 'bottom'})
      }
    } catch (error) {
      errorHandler(error)

    }
  }

  const sendVerificationEmail = async () => {
    try {
      if (currentUser) {
        sendEmailVerification(currentUser)
        Toast.show({ content: 'Verification Email has been sent', position: 'bottom'})
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    getCurrentUser()
  }, [currentUser])

  return (
    <div className='max-w-lg m-auto'>
      <NavBar style={{
        '--border-bottom': '1px #eee solid',
        }}
        onBack={() => navigate(-1)}
        >Edit Profile</NavBar>
      { currentUser && 
        <div className='flex flex-col items-center'>
          <div className='my-4' onClick={openFilePicker}>
            <Avatar src={avatarPhoto} style={{ '--border-radius': '96px', '--size': '96px' }} alt={displayName} />
            <input className='hidden' type='file' ref={inputFile} accept='image/*'
              onChange={filePickerChangeHandler} />
          </div>    
          <div className='mb-10'>
            Click photo to change
          </div>
          <div className='w-full'>
            <List style={{ '--border-bottom': '0px', '--border-inner': '0px', '--border-top': '0px' }}>
              <List.Item extra={currentUser.displayName} onClick={() => setShowUsernameDialog(true)}>
                Username
              </List.Item>
              <List.Item extra={currentUser.email} onClick={() => {setShowEmailDialog(true)}}>
                Email
              </List.Item>
              <List.Item onClick={sendVerificationEmail} disabled={currentUser.emailVerified}>
                Resend Verification Email
              </List.Item>
            </List>
          </div>
          <div>
            <Dialog 
              visible={showUsernameDialog} 
              content={<Input placeholder='Username' onChange={val => setDisplayname(val)}/>}
              closeOnAction
              onClose={() => setShowUsernameDialog(false)}
              actions={[[
                { key: 'cancel', text: 'Cancel' },
                { key: 'confirm', text: 'Update', onClick: changeName }
              ]]}
            />
            <Dialog 
              visible={showEmailDialog} 
              content={<Input placeholder='Email' onChange={val => setEmail(val)}/>}
              closeOnAction
              onClose={() => setShowEmailDialog(false)}
              actions={[[
                { key: 'cancel', text: 'Cancel' },
                { key: 'confirm', text: 'Update', onClick: changeEmail }
              ]]}
            />
          </div>
        </div>  
      }
    </div>
  )
}

export default EditProfile
import { Button } from 'antd-mobile'

function TextButton({ label, size='middle', color='default', fill='none', clickHandler }: any) {
  return (
    <div className='my-1'>
      <Button size={size} fill={fill} color={color} onClick={clickHandler}>
        {label}
      </Button>
    </div>
  )
}

export default TextButton
import { Dialog } from 'antd-mobile'
import { Bugfender } from '@bugfender/sdk'

Bugfender.init({
	appKey: 'ePHZ2SGsvGoaqfTXeEz9jxrsQdb9n8iG',
})

function errorHandler(error: any) {
  Bugfender.error(error)

  const code = error.code
  let message = error.message
  console.error(code, message)

  switch (code) {
    case 'auth/wrong-password':
      message = 'Wrong password'
      break
      
    case 'auth/user-not-found':
      message = 'User not found'
      break

    case 'auth/weak-password':
      message = 'Password should be at least 6 characters'
      break

    case 'auth/email-already-in-use':
      message = 'Email already in use'
      break

    case 'auth/invalid-email':
      message = 'Invalid email'
      break

    case 'auth/internal-error':
      message = 'Password cannot be empty'
      break

    default:
      message = 'Something went wrong'
  }

  Dialog.alert({content: message, confirmText: 'OK'})
}

export default errorHandler
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavBar } from 'antd-mobile'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { TagCloud } from 'react-tagcloud'

import BottomNav from '../components/BottomNav'
import { AppContext } from '../contexts/appContext'
import { hashtagConverter } from '../converters/Hashtag'
import { postConverter } from '../converters/Post'
import errorHandler from '../utils/errorHandler'

function Discover() {
  const { db } = useContext(AppContext)
  const tagsRef = collection(db, 'hashtags').withConverter(hashtagConverter)
  const postsRef = collection(db, 'posts').withConverter(postConverter)

  const navigate = useNavigate()
  const [tagList, setTagList] = useState<any>()

  const getTagList = async () => {
    try {
      const tagsDocs = await getDocs(tagsRef)
      const tags = tagsDocs.docs.map(doc => (
        {'value': doc.data().name, 'count': doc.data().count}
      ))
      setTagList(tags)
    } catch (error: any) {
      errorHandler(error)
    }
  }

  const getPostListByTag = async (tag: string) => {
    try {
      const q = query(postsRef, where('hashtags', 'array-contains', tag))
      const postsDocs = await getDocs(q)
      const posts = postsDocs.docs.map(doc => ({id: doc.id, data: doc.data()}))
      navigate('/listplayer', { state: {list: posts}})
    } catch (error) {
      errorHandler(error)
    }
  }
  
  useEffect(() => {
    getTagList()
  }, [])

  return (
    <div className='max-w-lg h-full m-auto flex flex-col'>
      <NavBar backArrow={false} style={{ '--border-bottom': '1px #eee solid'}}>Discover</NavBar>
      <div className='grow cursor-pointer mx-2'>
        {tagList && <TagCloud
          minSize={15}
          maxSize={35}
          tags={tagList}
          className="simple-cloud"
          onClick={(tag: any) => getPostListByTag(tag.value)}
        />}
      </div>
      <BottomNav darktheme={false} />
    </div>
  )
}

export default Discover